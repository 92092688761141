@import url(./assets/css/bootstrap.min.css);
@import url(./assets/css/bootstrap-icons.css);
@import url(./assets/css/all.min.css);
@import url(./assets/css/fontawesome.min.css);
@import url(./assets/css/swiper-bundle.min.css);
@import url(./assets/css/nice-select.css);
@import url(./assets/css/jQuery-plugin-progressbar.css);
@import url(./assets/css/barfiller.css);
@import url(./assets/sass/style.css);
.CircularProgressbar .CircularProgressbar-trail {
  stroke: #faf3f3;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #0a7cd9;
  stroke-linecap: round;
  -webkit-transition: stroke-dashoffset 0.5s ease 0s;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #0a86d9;
  font-size: 22px;
  font-weight: 700;
}

.single-portfolio.masonary.masonary-top-72 {
  margin-top: -72px;
}

.single-portfolio.masonary.massonary-top-30 {
  margin-top: -30px;
}

.single-portfolio.masonary.masonary-top-113 {
  margin-top: -113px;
}

.progressbar-label {
  color: #fff;
  padding-left: 10px;
}

.out-story .CircularProgressbar .CircularProgressbar-path {
  stroke: #d6d6d6;
}

.out-story .CircularProgressbar .CircularProgressbar-text {
  fill: #18191d;
  font-size: 18px;
  font-weight: 600;
  -webkit-transform: translateY(-22px);
          transform: translateY(-22px);
}

.wrapper {
  margin: auto;
  width: 60%;
  text-align: center;
  line-height: 2em;
}

.hover .cursor-dot {
  width: 100px;
  height: 100px;
  z-index: -1;
  background-color: lightblue;
}

.hover .cursor-border {
  z-index: -1;
}

.link {
  padding: 1em;
}

.react-hover .cursor-border {
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 0;
  z-index: -1;
}

.react-hover .cursor-dot {
  z-index: -3;
}

.author {
  color: orange;
  font-weight: 800;
  font-size: 1.3em;
}

.author-on-hover .cursor-dot {
  width: 100px;
  height: 100px;
  background-color: gray;
  z-index: -1;
}

.author-on-hover .cursor-border {
  z-index: -1;
}

.head {
  color: bisque;
}

.head-hover .cursor-border {
  border: 5px dotted darkorange;
}
/* Web Header */

.bg {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 100vw;
  height: 70vw;
  z-index: -1;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #061650 0deg,
    #ffffff 360deg
  );
  animation: rotate-gradient 7s linear infinite;
}

.containerd {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: 70vh;
  padding: 10%;
  box-sizing: border-box;
  overflow: hidden;
  color: #041d48;
}

h1 {
  font-size: 4rem;
}

h1 span {
  color: white;
}

p {
  font-size: 1.25rem;
  margin-top: 2rem;
}

@keyframes rotate-gradient {
  from {
    transform: rotate(0deg) scale(3);
  }
  to {
    transform: rotate(360deg) scale(3);
  }
}

/* Web Header End */


/* App Development */


.herod{
  width: 100%;
  height: 100vh;
  background-image: url("../public/images/techbg.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}
.herodm{
  width: 100%;
  height: 100vh;
  background-image: url("../public/images/dm1.png");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}
.buttond {
  color: #fff;
  padding: 10px 25px;
  background: transparent;
  border: 1px solid #7326ef;
  border-radius: 20px;
  outline: pointer;
}
.content {
  color: #fbfcfd;
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translateY(-50%);
  z-index: 2;
}


.bubbles img {
  width: 50px;
  animation: bubble 7s linear infinite;
  opacity: 0;
}
.bubbles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
}
@keyframes bubble {
  0%{
    transform: translateY(0);
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  75%{
    opacity: 1;
  }
  100%{
    transform: translateY(-80vh);
    opacity: 0;
  }
}

.bubbles img:nth-child(1) {
  animation-delay: 2s;
}
.bubbles img:nth-child(2) {
  animation-delay: 1s;
}
.bubbles img:nth-child(3) {
  animation-delay: 4s;
}
.bubbles img:nth-child(4) {
  animation-delay: 5s;
}
.bubbles img:nth-child(5) {
  animation-delay: 6s;
}
.bubbles img:nth-child(6) {
  animation-delay: 7s;
}
.bubbles img:nth-child(7) {
  animation-delay: 8s;
}





/* App Development End */


/* CMS Starts */

.herocmd{
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to bottom, rgba(194, 194, 195, 0.52), rgba(11, 89, 85, 0.73)),url("../public/images/nvr/hdr.png");
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

/* CMS Ends */
.head-hover .cursor-dot {
  background-color: purple;
  width: 20px;
  height: 20px;
}

.children {
  color: #72fd00;
}

.children-hover .cursor-dot {
  width: 600px;
  height: 600px;
  background-color: white;
  color: #60aff8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sub-menu li a {
  cursor: pointer !important;
}

.portfolio-hover a img {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  padding: 5px;
}

.SRLThumbnailsContainer.css-13uajvl {
  display: none !important;
}

.scroll-top.opacity {
  color: #0a6ed9;
  font-weight: 800;
}

/* Hire Start */

.hero-sectiond {
  height: 90vh;
  /* max-width: fit-content; */
  background-image: linear-gradient(to bottom, rgba(194, 194, 195, 0.52), rgba(11, 89, 85, 0.73)), url('../public/images/hire1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  color: rgb(18, 14, 99);
}

@media screen and (max-width:400px) {
  .hero-sectiond{
    max-width: auto;
  }
  .containerd{
    width: fit-content;
  }
  /* .hero-textd,.hero-text-descriptiond
  {
    width: 20px;
  }
  .hero-welcome-textd
  {
    width: 120px;
  } */
}

.containerd {
  width: 90%;
  margin: 0 auto;
}



.hero-textd {
  width: 450px;
  position: absolute;
  bottom: 10px;
  right: 20px;
  text-align: right;
}

.hero-welcome-textd {
  margin: 0;
  padding: 0;
  font-size: 34px;
  margin-bottom: -10px;
}

.hero-countryd {
  font-size: 100px;
  margin: 0;
  padding: 0;
}

.hero-text-descriptiond {
  margin: 0;
  padding-bottom: 20px;
}





.card {
  position: relative;
  min-width: 300px;
  height: auto;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 40px 20px;
  box-shadow: 0 10px 15px rgba(0,0,0,0.3);
  transition: .5s;
}
.card:hover {
  transform:scale(1.1);
}
.card_red, .card_red .title .fa {
  background: linear-gradient(-45deg, #ffec61, #f321d7);
}
.card_violet, .card_violet .title .fa  {
  background: linear-gradient(-45deg, #f403d1, #64b5f6);
}
.card_three, .card_three .title .fa  {
  background: linear-gradient(-45deg, #b7f4cc, #9a4eff);
}

.card:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background: rgba(255, 255, 255, .1);
  z-index: 1;
  transform: skewY(-5deg) scale(1.5);
}

.title .fa {
  color: #fff;
  font-size: 60px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
}
.title h2 {
  position: relative;
  margin: 20px 0 0;
  padding: 0;
  color: #fff;
  font-size: 28px;
  z-index: 2;
}
.price {
  position: relative;
  z-index: 2;
}
.price h4 {
  margin: 0;
  padding: 20px 0;
  color: #fff;
  font-size: 60px;
}
.option {
  position: relative;
  z-index: 2;
}
.option ul {
  margin: 0;
  padding: 0;
}
.option ul li {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  color: #fff;
  font-size: 16px;
}
.card a {
  display: block;
  position: relative;
  z-index: 2;
  background-color: #fff;
  color: '#262ff';
  width: 150px;
  height: 40px;
  text-align: center;
  margin: 20px auto 0;
  line-height: 40px;
  border-radius: 40px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 5px 10px rgba(0,0,0, .1);
}

/* Hire Ends */


/*  */